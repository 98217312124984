/* eslint-disable react/jsx-no-target-blank */
function BestPractice() {
  return (
    <>
      <div class="section page-banner-section">
        <div class="shape-2"></div>
        <div class="container">
          <div class="page-banner-wrap">
            <div class="row">
              <div class="col-lg-12">
                {/* <!-- Page Banner Content Start --> */}
                <div class="page-banner text-center">
                  <h2 class="title">BEST PRACTICES FOR OPEN MATS</h2>
                </div>
                {/* <!-- Page Banner Content End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-10 mx-auto my-5 shadow p-4 rounded border">
            <li className="py-2 source-font">
              Always call ahead; Schedules can and do change especially when
              there are tournaments going on. You are a guest, please be
              respectful at all times
            </li>
            <li className="py-2 source-font">
              Remember that you represent your gym and your professors; the Jiu
              Jitsu world is still small and in most cities, It’s a tight knit
              community and there is a good chance that they know your professor
              This is not a tournament so you don’t need to “win” every roll
            </li>
            <li className="py-2 source-font">
              It is ok to discuss format before the roll; will you start sitting
              or standing, injuries to watch out for, a specific focus, etc.
            </li>
            <li className="py-2 source-font">
              This is a great opportunity to try things you’ve been learning and
              see how they match up to what other gyms are learning Lose
              gracefully, no spazzing
            </li>
            <li className="py-2 source-font">Along those lines, tap early and often</li>
            <li className="py-2 source-font">
              Finish calmly, if you can’t finish in a controlled fashion, reset
              and establish a better base to finish
            </li>
            <li className="py-2 source-font">
              Don’t ask girls to roll, if they ask you, work on technique and
              not strength. Most gyms defend their girls fiercely, so always be
              respectful No leg locks with anyone below a purple belt, but be
              prepared because they might not adhere to this rule!
            </li>
            <li className="py-2 source-font">
              ALWAYS PROTECT YOURSELF; just because you’re doing things the
              right way it doesn’t mean that your opponent will. Be willing to
              disengage from a roll if you feel your partner is not safe
            </li>
            <li className="py-2 source-font">
              Unless you know the gym, always wear a white or blue gi…
              preferably with no gym patches
            </li>
            <li className="py-2 source-font">
              It should go without saying but make sure your gi is clean, you’ve
              showered recently and your nails are clipped
            </li>
            <li className="py-2 source-font">
              Open mats are free at most gyms, but some do charge, I always call
              ahead to get details
            </li>
            <li className="py-2 source-font">Rest rounds are helpful</li>
            <li className="py-2 source-font">Have fun!</li>
            <li className="py-2 source-font">If you’re new to BJJ and have never been to an open mat, here are a few articles that may help:</li>
            <a className="py-2 source-font px-4" style={{textDecoration: "underline"}} href="https://tfcgym.com.au/bjj-open-mat/" target="_blank">https://tfcgym.com.au/bjj-open-mat/</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default BestPractice;
