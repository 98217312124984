import React, { useState } from 'react'
import axiosInstance from '../../utlilites/axios';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

const Newsletter = () => {
    const [value , setValue] = useState('');
    const [loader, setLoader] = useState(false);
    const handlerSubmit = (e) => {
        setLoader(true);
        e.preventDefault();
        const values = {
            email : value
        }
        axiosInstance.post('user/news/subscription' , values).then((data) => {
            console.log(data);
            toast.success("Subcription Successsfully");
            setLoader(false);
            setValue("");
        }).catch((err) => {
            console.log(err);
            toast.error('The email has already been taken.');
            setLoader(false);
       })
    }
    return (
        <>
            <div class="newsletter-section">
                <div class="container">
                    <div class="newsletter-wrap">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                {/* <!-- Newsletter Text Start --> */}
                                <div class="newsletter-text">
                                    <h3 class="title">Subscribe to our newsletter</h3>
                                </div>
                                {/* <!-- Newsletter Text End --> */}
                            </div>
                            <div class="col-lg-7">
                                {/* <!-- Newsletter Form Start --> */}
                                <div class="newsletter-form">
                                    <form onSubmit={handlerSubmit}> 
                                        <input type="email" placeholder="Your Email" value={value} onChange={(e) => setValue(e.target.value)} required />
                                            <button class="btn btn-primary" type="submit">
                                            {loader ? <Loader /> : "Subscribe"} 
                                                </button>
                                    </form>
                                </div>
                                {/* <!-- Newsletter Form End --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newsletter

