/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../utlilites/axios";
import { toast } from 'react-toastify';
import Loader from "../../Components/loader/Loader";
import { useNavigate } from "react-router-dom"

const RegisterForm = () => {

    const [loader, setLoader] = useState(false);

    const navigate = useNavigate()
    const loginSchema = Yup.object().shape({
        username: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
            Confirm_Password: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Password must match"),
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
            password: "",
            Confirm_Password: "",
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            setLoader(true);
            console.log(values.errors, "formErrors");
            try {
                const loginData = new FormData();
                loginData.append("username", values?.username);
                loginData.append("email", values?.email);
                loginData.append("password", values?.password);
                loginData.append("password_confirmation", values?.Confirm_Password);
                axiosInstance.post("user/registration", loginData).then((response) => {
                    if (response?.data?.status === true) {
                        setLoader(false);
                        console.log(response, "Register");
                        localStorage.setItem('user_id',response?.data?.data?.id)
                        toast.success('Register SuccessFully')
                        toast.info(response?.data?.message)
                        navigate("/otp")
                    }
                });
            } catch (error) {
                setLoader(false);
                toast.error("Error")

                console.error(error);
            }
        },
    });


    return (
        <>
            <div className="section login-register-section section-padding">
                <div className="container">

                    {/* <!-- Login & Register Wrapper Start --> */}
                    <div className="login-register-wrap">
                        <div className="row gx-5 justify-content-center align-items-center">
                            <div className="col-lg-6">

                                {/* <!-- Login & Register Box Start --> */}
                                <div className="login-register-box">
                                    {/* <!-- Section Title Start --> */}
                                    <div className="section-title">
                                        <h2 className="title">Register</h2>
                                    </div>
                                    {/* <!-- Section Title End --> */}

                                    <div className="login-register-form">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="single-form">
                                                <input type="text" className="form-control" placeholder="User Name " name='username' 
                                                 onChange={formik.handleChange}
                                                 value={formik.values.username}
                                                />
                                            </div>
                                            {formik.errors.username && (
                                                <div className="error">{formik.errors.username}</div>
                                            )}
                                            <div className="single-form">
                                                <input type="email" className="form-control" placeholder="email " name='email' 
                                                 onChange={formik.handleChange}
                                                 value={formik.values.email}
                                                />
                                            </div>
                                            {formik.errors.email && (
                                                <div className="error">{formik.errors.email}</div>
                                            )}
                                            <div className="single-form">
                                                <input type="password" className="form-control" placeholder="Password " name='password' 
                                                 onChange={formik.handleChange}
                                                 value={formik.values.password}
                                                />
                                            </div>
                                            {formik.errors.password && (
                                                <div className="error">{formik.errors.password}</div>
                                            )}
                                            <div className="single-form">
                                                <input type="password" className="form-control" placeholder="Confirm Password " name='Confirm_Password' 
                                                onChange={formik.handleChange}
                                                value={formik.values.Confirm_Password}
                                                />
                                            </div>
                                            {formik.errors.Confirm_Password && (
                                                <div className="error">{formik.errors.Confirm_Password}</div>
                                            )}
                                            <div className="form-btn">
                                                <button type='submit' className="btn-2">
                                                    {loader ?
                                                        <Loader />
                                                        :
                                                        'Register'
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <!-- Login & Register Box End --> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RegisterForm