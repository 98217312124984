/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useNavigate } from "react-router-dom";

const EventsCard = ({ data, index }) => {
  const navigate = useNavigate();

  const handlenavigate = (id, data) => {
    navigate(`/detail/${id}`, {
      state: data,
    });
  };
  console.log(data);
  return (
    <>
      
        <div class="col-lg-6 col-sm-6 cursor-pointer">
          {/* <!-- Event List Item Start --> */}
          <div class="event-list-item py-2 my-3 shadow h-200 border rounded">
            <div
              className="row c-pointer"
              onClick={() => handlenavigate(data?.id, data)}
            >
              {/* <div className="col-lg-4">
                <div class="event-img">
                  <img src={data?.image ? data?.image : "/assets/images/event/event-list-1.jpg"} alt="" />
                  <a href="event-single.html"></a>
                </div>
              </div> */}
              <div className="col-lg-8">
                <div class="event-list-content">
                  <h3 class="title">{data?.name}</h3>
                  <div class="meta-data">
                    <span>
                      <i class="fas fa-clock"></i>{" "}
                      {data?.open_mat_time}
                    </span>
                    {" "}
                    <span>
                      <i class="fas fa-map-marker-alt"></i>
                      {data?.region + " " + data?.state}
                    </span>
                    
                    <span>
                    <i class="fas fa-calendar"></i>
                      {data?.open_mat_day}
                    </span>
                  </div>
                  <div class="event-desc">
                    <p>{data?.other_info}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Event List Item End --> */}
        </div>
    </>
  );
};

export default EventsCard;
