/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';
import axiosInstance from '../../utlilites/axios';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

const CitiesSlider = () => {
  const [FilterData, setFilterData] = useState([]);
  const allEvents = () => {
    try {
      axiosInstance.get("user/get/trending/mats").then((response) => {
        if (response?.data?.status === true) {
          console.log(response, "trending");
          setFilterData(response?.data?.data);
        }
      });
    } catch (error) {
      toast.error("Error");
      console.error(error);
    }
  };

  useEffect(() => {
    allEvents();
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {
          FilterData ?
            FilterData?.map((item) => {
              return <SwiperSlide>
                <div class="trending-item">
                  <div class="event-list-content">
                    <h3 class="title">{item?.name}</h3>
                    <div class="meta-data">
                      <div>
                        <i class="fas fa-clock"></i>{" "}
                        {item?.open_mat_time}
                      </div>
                      {" "}
                      <div>
                        <i class="fas fa-map-marker-alt"></i>{" "}
                        {item?.region + " " + item?.state}
                      </div>

                      <div>
                        <i class="fas fa-calendar"></i>{" "}
                        {item?.open_mat_day}
                      </div>
                    </div>
                    <div class="event-desc">
                      <p>{item?.other_info}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            })
            : <Loader />}
      </Swiper>
    </>
  )
}

export default CitiesSlider