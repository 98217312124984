import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./Pages/Home/Home";
import Login from './Pages/Auth/Login';
import EventList from './Pages/Events/EventList';
import Detail from './Pages/Events/Detail';
import Register from './Pages/Auth/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Verify from './Pages/Auth/Verify';
import BestPractice from './Pages/BestPractice/BestPractice';


function App() {
  return (
    <>
      <BrowserRouter>
      <ToastContainer />
        <Layout>
          <Routes>
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/bestpractices" element={<BestPractice />} />
            <Route exact path="/otp" element={<Verify />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/events" element={<EventList />} />
            <Route exact path="/detail/:id" element={<Detail />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
