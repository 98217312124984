/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import { toast } from 'react-toastify';
import Loader from "../../Components/loader/Loader";

const LoginForm = () => {

    const [loader, setLoader] = useState(false);
    const loginSchema = Yup.object().shape({
        email: Yup.string().required("Required"),
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            setLoader(true);
            console.log(values.errors, "formErrors");
            try {
                const loginData = new FormData();
                loginData.append("login_identifier", values?.email);
                loginData.append("password", values?.password);
                axiosInstance.post("user/login", loginData).then((response) => {
                    if (response?.data?.status === true) {
                        setLoader(false);
                        console.log(response, "login");
                        setSession(response?.data?.token, response?.data?.data);
                        toast.success('Login SuccessFully')
                        window.location.href = "/"

                    }
                });
            } catch (error) {
                setLoader(false);
                toast.error("Error")

                console.error(error);
            }
        },
    });

    return (
        <>
            <div class="section login-register-section section-padding">
                <div class="container">

                    {/* <!-- Login & Register Wrapper Start --> */}
                    <div class="login-register-wrap">
                        <div class="row gx-5 justify-content-center align-items-center">
                            <div class="col-lg-6">

                                {/* <!-- Login & Register Box Start --> */}
                                <div class="login-register-box">
                                    {/* <!-- Section Title Start --> */}
                                    <div class="section-title">
                                        <h2 class="title">Login</h2>
                                    </div>
                                    {/* <!-- Section Title End --> */}

                                    <div class="login-register-form">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div class="single-form">
                                                <input type="text" class="form-control" placeholder="Username or email" name='email' 
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                />
                                            </div>
                                            {formik.errors.email && (
                                                <div className="error">{formik.errors.email}</div>
                                            )}
                                            <div class="single-form">
                                                <input type="password" class="form-control" placeholder="Password" name='password' 
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                />
                                            </div>
                                            {formik.errors.password && (
                                                <div className="error">{formik.errors.password}</div>
                                            )}
                                            <div class="single-form form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                                            </div>
                                            <div class="form-btn">
                                                <button type='submit' class="btn-2">
                                                    {loader ? <Loader /> : "Login"}
                                                </button>
                                            </div>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className='col-lg-4'>
                                                    <div class="single-form">
                                                        <p><a href="#">Lost your password?</a></p>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 pe-0'>
                                                    <div class="single-form">
                                                        <p><a href="#">If you don't have account <Link to='/register' className='text-primary'>Register</Link></a></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/* <!-- Login & Register Box End --> */}

                            </div>

                        </div>
                    </div>
                    {/* <!-- Login & Register Wrapper End --> */}

                </div>
            </div>
        </>
    )
}

export default LoginForm