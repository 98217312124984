import React from 'react'
import RegisterForm from '../../Components/Auth/RegisterForm'

const Register = () => {
  return (
    <>
         <div class="section page-banner-section">
                <div class="shape-2"></div>
                <div class="container">
                    <div class="page-banner-wrap">
                        <div class="row">
                            <div class="col-lg-12">
                                {/* <!-- Page Banner Content Start --> */}
                                <div class="page-banner text-center">
                                    <h2 class="title">Register</h2>
                                  
                                </div>
                                {/* <!-- Page Banner Content End --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RegisterForm/>
    </>
  )
}

export default Register