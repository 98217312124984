/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import EventsList from "../../Components/HomePage/EventsList";
import axiosInstance from "../../utlilites/axios";
import { toast } from "react-toastify";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

const Detail = () => {
  const location = useLocation();
  const data = location?.state;
  console.log(data?.longitude, data?.latitude, "data");
  const [FilterData, setFilterData] = useState([]);

  const containerStyle = {
    width: "100%",
    height: "60vh",
  };

  const allEvents = () => {
    try {
      axiosInstance.get("user/view/mats").then((response) => {
        if (response?.data?.status === true) {
          const fill = response?.data?.data;
          console.log(response?.data?.data, "login");
          const filterData = fill?.filter((item) => {
            return (
              item?.location === data?.location &&
              item?.open_mat_time === data?.open_mat_time
            );
          });
          console.log(filterData);
          setFilterData(filterData);
        }
      });
    } catch (error) {
      toast.error("Error");
      console.error(error);
    }
  };
  useEffect(() => {
    allEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const apiKey = "AIzaSyBiIvCLftcHwIONNtyTUsGHkS1vZuCPeuw";

  return (
    <>
      <div class="section page-banner-section">
        <div class="shape-2"></div>
        <div class="container">
          <div class="page-banner-wrap">
            <div class="row">
              <div class="col-lg-12">
                {/* <!-- Page Banner Content Start --> */}
                <div class="page-banner text-center">
                  <h2 class="title">Open Mat</h2>
                </div>
                {/* <!-- Page Banner Content End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div class="meeta-event-single section-padding1">
          <div class="meeta-event-single-wrap">
            <div class="row">
              <div class="col-lg-8">
                <div class="event-single-content">
                  <h1>{data?.name}</h1>
                  <div class="meeta-video-section-2">
                    <div
                      class="video-img text-center"
                      style={{
                        backgroundImage: `url(${
                          data?.image
                            ? data?.image
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/GABRIEL_VELLA_vs_ROMINHO_51.jpg/800px-GABRIEL_VELLA_vs_ROMINHO_51.jpg"
                        })`,
                      }}
                    >
                      {/* <!-- Section Title Start --> */}
                      {/* <div class="meeta-section-title-2 section-title-4">
                                                    <h2 class="main-title">Digital Workshop <br /> Conferencee 2021</h2>
                                                </div> */}
                      {/* <!-- Section Title End --> */}
                      {/* <a class="popup-video" href="https://www.youtube-nocookie.com/embed/Ga6RYejo6Hk"><i class="fas fa-play"></i></a> */}
                    </div>
                  </div>
                  {/* <!-- Video End --> */}
                  {/* <p>{data?.other_info}</p> */}
                  {/* <div class="event-single-item"> */}
                  {/* <h3 class="title">Related Open Mat</h3> */}
                  {/* <div className="row">
                      <EventsList data={FilterData} />
                    </div> */}
                  {/* </div> */}
                </div>
                {/* <!-- Event Single Content End --> */}
              </div>
              <div class="col-lg-4">
                {/* <!-- Event Single Sidebar Start --> */}
                <div class="event-single-sidebar">
                  {/* <div class="btn-price">
                                            <Link class="btn btn-primary" to={data?.link_to_waiver} target='_blank'>Participate</Link>
                                            </div> */}
                  {/* <div class="price">
                                                <span><sup>$</sup>65</span>
                                            </div> */}
                  <div class="sidebar-item">
                    <div class="event-details">
                      <h3 class="sidebar-title">Details</h3>
                      <ul>
                        <li>
                          <div className="row">
                            <div className="col-lg-6">
                              <h5 class="title"> Time</h5>
                              <p>{data?.open_mat_time}</p>
                            </div>

                            <div className="col-lg-6">
                              <h5 class="title">Day</h5>
                              <p>{data?.open_mat_day}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-lg-6">
                              <h5 class="title">phone :</h5>
                              <p>{data?.phone_number}</p>
                            </div>

                            <div className="col-lg-6">
                              <h5 class="title">Date :</h5>
                              <p>{data?.event_date}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <h5 class="title">Website :</h5>
                          <a href={data?.website}>{data?.website}</a>
                        </li>
                        <li>
                          <h5 class="title">Address :</h5>
                          <p>{data?.physical_address}</p>
                        </li>
                        <li>
                          <h5 class="title">Location :</h5>
                          <p>{data?.location}</p>
                        </li>
                        <li>
                          <div className="row">
                            <div className="col-lg-6">
                              <h5 class="title">Region</h5>
                              <p>{data?.region}</p>
                            </div>
                            <div className="col-lg-6">
                              <h5 class="title">State</h5>
                              <p>{data?.state}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- Event Single Sidebar End --> */}
              </div>
                  <div class="sidebar-item">
                    <div class="event-map">
                      <h3 class="sidebar-title">Location Map</h3>
                      <div class="google-map">
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={{
                            lat: Number(data?.latitude),
                            lng: Number(data?.longitude),
                          }}
                          zoom={5}
                        >
                          <MarkerF
                            position={{
                              lat: Number(data?.latitude),
                              lng: Number(data?.longitude),
                            }}
                          />
                        </GoogleMap>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
