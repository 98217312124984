/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Footer = () => {
    return (
        <>
            {/* <!-- Footer Start --> */}
            <div class="meeta-footer-5" style={{ backgroundImage: "url(/assets/images/bg/footer-5-bg.jpg)" }}>

                {/* <!-- Footer Widget Start --> */}
                <div class="footer-widget text-center">
                    <div class="container">

                        {/* <!-- Footer Logo Start -->/ */}
                        <div class="footer-logo">
                            <a href="#"><img src="/assets/images/logo1.png" alt="Logo" width={150} className='img-fluid'/></a>
                        </div>
                        {/* <!-- Footer Logo End --> */}

                        <div class="footer-menu">
                            <ul>
                                <li><a href="#">Home</a></li>
                                {/* <li><a href="about.html">About Us</a></li>
                                <li><a href="speaker-single.html">Speaker</a></li>
                                <li><a href="event-single.html">Sponsor</a></li>
                                <li><a href="blog.html">Blog </a></li>
                                <li><a href="contact.html"> Contact</a></li> */}
                            </ul>
                        </div>

                        {/* <!-- Footer widget Social Start --> */}
                        <div class="footer-widget-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-linkedin-in"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                        </div>
                        {/* <!-- Footer widget Social End --> */}

                        {/* <!-- Footer Copyright Start --> */}
                        <div class="footer-copyright">
                            <p>2024 Copyright OpenMat Designed by <a href='#'>HnH Soft Tech Solutions</a>. All Rights Reserved</p>
                        </div>
                        {/* <!-- Footer Copyright End --> */}

                    </div>
                </div>
                {/* <!-- Footer Widget End --> */}

            </div>
            {/* <!-- Footer End --> */}
        </>
    )
}

export default Footer