/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../utlilites/axios";
import { toast } from "react-toastify";
import { Autocomplete } from "@react-google-maps/api";
import Loader from "../../Components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

const Banner = () => {
  const navigate = useNavigate();
  const [Location, setLocation] = useState("");
  const [State, setState] = useState("");
  const [Regions, setRegions] = useState([]);
  const [Region, setRegion] = useState("");
  const [events, setEvents] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("");
  const [day, setDay] = useState("");
  const [FilterLoader, setFilterLoader] = useState("");
  const [date, setDate] = useState("");
  const originref = useRef();
  const LocationRef = useRef();
  const autoCompleteRef = useRef();
  const autoCompleteLocationRef = useRef();

  const allRegions = () => {
    setFilterLoader(true);
    try {
      axiosInstance.get("user/get/regions").then((response) => {
        if (response?.data?.status === true) {
          setFilterLoader(false);
          console.log(response, "login");
          setRegions(response?.data?.data);
        }
      });
    } catch (error) {
      setFilterLoader(false);
      toast.error("Error");
      console.error(error);
    }
  };

  useEffect(() => {
    allRegions();
  }, []);

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      originref?.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const Place = await autoCompleteRef.current.getPlace();
      // console.log(place.geometry.location.lat(),"place");
      // console.log(place.geometry.location.lng(),"place");

      // setPlaceName(place.formatted_address);
      setState(Place?.formatted_address);
      console.log(Place?.name, "place");
    });
  }, [State]);

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteLocationRef.current =
      new window.google.maps.places.Autocomplete(LocationRef.current);
    autoCompleteLocationRef.current.addListener(
      "place_changed",
      async function () {
        const Place = await autoCompleteLocationRef.current.getPlace();
        // console.log(place.geometry.location.lat(),"place");
        // console.log(place.geometry.location.lng(),"place");

        // setPlaceName(place.formatted_address);
        setLocation(Place?.formatted_address);
        console.log(Place?.name, "place");
      }
    );
  }, [Location]);

  const Search = () => {
    try {
      setFilterLoader(true);
      const SearchData = new FormData();
      // SearchData.append("event_location", Location);
      // SearchData.append("event_title", title);
      // SearchData.append("event_date", date);
      SearchData.append("event_day", day);
      SearchData.append("event_state", State);
      SearchData.append("region", Region);

      axiosInstance
        .post("user/filter/mat/data", SearchData)
        .then((response) => {
          if (response?.data?.status === true) {
            setFilterLoader(false);
            setEvents(response?.data?.data);
            toast.success(response?.data?.message);
            console.log(response?.data, "serach");
            setDay("");
            setState("");
            setRegion("");
          }
        });
    } catch (error) {
      setFilterLoader(false);
      toast.error("Error");
      console.error(error);
    }
  };

  console.log(LocationRef?.current?.value, "Location");
  console.log(State?.current?.value, "State");

  const containerStyle = {
    width: "100%",
    height: "70vh",
  };

  const allEvents = () => {
    try {
      axiosInstance.get("user/view/mats").then((response) => {
        console.log(response?.data, "event");
        if (response?.data?.status === true) {
          setEvents(response?.data?.data);
        }
      });
    } catch (error) {
      toast.error("Error");
      console.error(error);
    }
  };

  useEffect(() => {
    allEvents();
  }, []);

  const handleMarkerClick = (e) => {
    navigate(`/detail/${e?.id}`, {
      state: e,
    })
  }

  return (
    <>
      <div class="meeta-hero-section-5 d-flex align-items-center bg-banner">
        <div className="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="hero-content">
                <div class="title-wrap text-center">
                  {/* <h2 class="title">Find event</h2> */}
                  <h1 class="title-wrap text-center">
                    Pick your city and training day
                  </h1>
                </div>
                <div class="search-form-wrap">
                  <div class="search-form-inner">
                    <form class="search-form" action="#">
                      <div className="row w-100 mx-auto">
                        {/* <div className="col-lg-2 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-map"></i>Location
                            </label>
                            <Autocomplete>
                              <input
                                type="text"
                                ref={LocationRef}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                            </Autocomplete>
                          </div>
                        </div> */}

                        {/* <div className="col-lg-2 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-search"></i> Event Title
                            </label>
                            <input
                              type="text"
                              placeholder="Type Event Name"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>   */}
                        <div className="col-lg-3 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-calendar"></i>Location
                            </label>
                            <select
                              className="nice-select filter"
                              onChange={(e) => setRegion(e.target.value)}
                            >
                              <option>Select Location</option>
                              {Regions?.map((e, index) => {
                                return (
                                  <>
                                    <option key={index} value={e}>
                                      {e}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-calendar"></i> Event Day
                            </label>
                            <select
                              className="nice-select filter"
                              onChange={(e) => setDay(e.target.value)}
                            >
                              <option>Select Day</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                              <option value="Sunday">Sunday</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-map"></i>State
                            </label>
                            <Autocomplete>
                              <input
                                type="text"
                                ref={originref}
                                placeholder="Enter State"
                                onChange={(e) => setState(e.target.value)}
                              />
                            </Autocomplete>
                          </div>
                        </div>

                        {/* <div className="col-lg-2 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                            <i class="fas fa-map"></i>Date
                            </label>
                            <input
                              type="date"
                              placeholder="Enter Date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                        </div> */}

                        <div class="col-md-3">
                          <div class="form-btn">
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={Search}
                            >
                              {FilterLoader ? <Loader /> : "Find Event"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 mt-5">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{
                lat: 32.7832601,
                lng: -117.0988523,
              }}
              zoom={9}
            >
              {events?.map((e, index) => (
                <MarkerF
                  key={e.id}
                  position={{
                    lat: Number(e?.latitude),
                    lng: Number(e?.longitude),
                  }}
                  onClick={() => handleMarkerClick(e)}
                />
              ))}
            </GoogleMap>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-4 mx-auto text-center col-12 mt-5 form-btn">
                <button
                  className="w-100 btn btn-primary"
                  onClick={() =>
                    navigate("/events", {
                      state: events,
                    })
                  }
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
