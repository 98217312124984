/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import LoginForm from '../../Components/Auth/LoginForm'

const Login = () => {
    return (
        <>
            <div class="section page-banner-section">
                <div class="shape-2"></div>
                <div class="container">
                    <div class="page-banner-wrap">
                        <div class="row">
                            <div class="col-lg-12">
                                {/* <!-- Page Banner Content Start --> */}
                                <div class="page-banner text-center">
                                    <h2 class="title">Login</h2>
                                  
                                </div>
                                {/* <!-- Page Banner Content End --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginForm />
        </>
    )
}

export default Login