import React from "react";
import Banner from "../../Components/HomePage/Banner";
import Cities from "../../Components/HomePage/Cities";
// import EventsList from "../../Components/HomePage/EventsList";
// import UpcomingEvents from "../../Components/Slider/UpcomingEvents";
// import Sponsers from "../../Components/HomePage/Sponsers";
import Newsletter from "../../Components/HomePage/Newsletter";
// import { GoogleMap, MarkerF } from "@react-google-maps/api";

const Home = () => {
  // const containerStyle = {
  //   width: "100%",
  //   height: window.innerHeight,
  // };
  // const address = [
  //   { lat: 37.7749, lng: -122.4194 },
  //   { lat: 27.7749, lng: -122.4184 },
  //   { lat: 17.7749, lng: -122.4174 },
  //   { lat: 7.7749, lng: -122.4164 },
  //   { lat: 37.7749, lng: -112.4194 },
  //   { lat: 27.7749, lng: -112.4184 },
  //   { lat: 17.7749, lng: -112.4174 },
  //   { lat: 7.7749, lng: -112.4164 },
  //   { lat: 37.7749, lng: -102.4194 },
  //   { lat: 27.7749, lng: -102.4184 },
  //   { lat: 17.7749, lng: -102.4174 },
  //   { lat: 7.7749, lng: -102.4164 },
  //   { lat: 37.7749, lng: -92.4194 },
  //   { lat: 27.7749, lng: -92.4184 },
  //   { lat: 17.7749, lng: -92.4174 },
  //   { lat: 7.7749, lng: -92.4164 },
  //   { lat: 37.7749, lng: 122.4194 },
  //   { lat: 27.7749, lng: 122.4184 },
  //   { lat: 17.7749, lng: 122.4174 },
  //   { lat: 7.7749, lng: 122.4164 },
  //   { lat: 37.7749, lng: 112.4194 },
  //   { lat: 27.7749, lng: 112.4184 },
  //   { lat: 17.7749, lng: 112.4174 },
  //   { lat: 7.7749, lng: 112.4164 },
  //   { lat: 37.7749, lng: 102.4194 },
  //   { lat: 27.7749, lng: 102.4184 },
  //   { lat: 17.7749, lng: 102.4174 },
  //   { lat: 7.7749, lng: 102.4164 },
  //   { lat: 37.7749, lng: 92.4194 },
  //   { lat: 27.7749, lng: 92.4184 },
  //   { lat: 17.7749, lng: 92.4174 },
  //   { lat: 7.7749, lng: 92.4164 },
  // ];
  // const markerOptions = {
  //   // Customize the marker icon
  //   icon: {
  //     path: "M8 2C4.4 2 2 4.4 2 8s6 12 6 12 6-9.6 6-12S11.6 2 8 2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-2.2 0-4-1.8-4-4h8c0 2.2-1.8 4-4 4z",
  //     fillColor: "blue", // Change the fill color of the marker
  //     fillOpacity: 1,
  //     strokeColor: "white",
  //     strokeWeight: 2,
  //     scale: 2, // Adjust the size of the marker
  //   },
  // };
  // const markerOptions2 = {
  //   // Customize the marker icon
  //   icon: {
  //     path: "M8 2C4.4 2 2 4.4 2 8s6 12 6 12 6-9.6 6-12S11.6 2 8 2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-2.2 0-4-1.8-4-4h8c0 2.2-1.8 4-4 4z",
  //     fillColor: "black", // Change the fill color of the marker
  //     fillOpacity: 1,
  //     strokeColor: "white",
  //     strokeWeight: 2,
  //     scale: 2, // Adjust the size of the marker
  //   },
  // };
  return (
    <>
      <Banner />
      {/* <div className="row">
        <div className="col-md-12">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: address[0]?.lat, lng: address[0]?.lng }}
            zoom={5}
          >
            {address.map((e) => (
              <MarkerF
                position={{ lat: e?.lat, lng: e?.lng }}
                options={
                  e?.lng.toString().includes("-")
                    ? markerOptions
                    : markerOptions2
                }
              />
            ))}
          </GoogleMap>
        </div>
      </div> */}
      <Cities />
      {/* <div class="meeta-featured-section section-padding">
        <div class="shape-1"></div>
        <div class="shape-2" data-aos-delay="700" data-aos="zoom-in"></div>
        <div class="container">
          <div class="meeta-featured-wrap">
            <div class="meeta-section-title section-title-4 text-center">
              <h2 class="main-title">
                <span class="title-shape-1">Featured </span>Events
              </h2>
            </div>
            <div class="meeta-event-featured">
              <div class="row">
                <EventsList />
                <EventsList />
                <EventsList />
                <EventsList />
                <EventsList />
                <EventsList />
              </div>
            </div>
            <div class="featured-more text-center">
              <a class="btn-2" href="event-single.html">
                More Featured Events
              </a>
            </div>
          </div>
        </div>
      </div>

      <UpcomingEvents />
      <Sponsers /> */}
      <Newsletter />

      
    </>
  );
};

export default Home;
