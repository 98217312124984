import React from 'react';
import { ColorRing } from 'react-loader-spinner'; 

function Loader() {
  return (
<ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="color-ring-loading"
  wrapperClass="color-ring-wrapper"
  colors={['#fff','#000','#000','#000','#000']}
/>

  );
}

export default Loader;
