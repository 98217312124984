import React from 'react'
import VerifyForm from '../../Components/Auth/VerifyForm'

const Verify = () => {
    return (
        <>
            <div class="section page-banner-section">
                <div class="shape-2"></div>
                <div class="container">
                    <div class="page-banner-wrap">
                        <div class="row">
                            <div class="col-lg-12">
                                {/* <!-- Page Banner Content Start --> */}
                                <div class="page-banner text-center">
                                    <h2 class="title">OTP</h2>

                                </div>
                                {/* <!-- Page Banner Content End --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <VerifyForm />
        </>
    )
}

export default Verify