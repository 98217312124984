/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Autocomplete,
  // GoogleMap,
  // Marker,
  // MarkerF,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { RotatingSquare } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import EventsCard from "../../Components/Cards/EventsCard";
import Loader from "../../Components/loader/Loader";
import axiosInstance from "../../utlilites/axios";
const EventList = () => {
  const location = useLocation();

  const data = location?.state;

  const [loader, setLoader] = useState(false);
  const [events, setEvents] = useState([]);
  const [Regions, setRegions] = useState([]);
  const [Region, setRegion] = useState("");
  const [Location, setLocation] = useState("");
  const [State, setState] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState("");
  const [day, setDay] = useState("");
  const [FilterLoader, setFilterLoader] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState("");

  const originref = useRef();
  const LocationRef = useRef();
  const autoCompleteRef = useRef();
  const autoCompleteLocationRef = useRef();

  const allEvents = () => {
    setLoader(true);
    try {
      axiosInstance.get("user/view/mats").then((response) => {
        if (response?.data?.status === true) {
          setLoader(false);
          console.log(response, "login");
          setEvents(response?.data?.data);
        }
      });
    } catch (error) {
      setLoader(false);
      toast.error("Error");
      console.error(error);
    }
  };

  const allRegions = () => {
    setLoader(true);
    try {
      axiosInstance.get("user/get/regions").then((response) => {
        if (response?.data?.status === true) {
          setLoader(false);
          console.log(response, "login");
          setRegions(response?.data?.data);
        }
      });
    } catch (error) {
      setLoader(false);
      toast.error("Error");
      console.error(error);
    }
  };

  useEffect(() => {
    allRegions();
  }, []);

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      originref.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const Place = await autoCompleteRef.current.getPlace();
      console.log(Place.geometry.location.lat(), "Place");
      console.log(Place.geometry.location.lng(), "Place");

      // setPlaceName(place.formatted_address);
      setState(Place?.formatted_address);
      console.log(Place?.name, "place");
    });
  }, [State]);

  useEffect(() => {
    console.log("useeffect", +1);
    autoCompleteLocationRef.current =
      new window.google.maps.places.Autocomplete(LocationRef.current);
    autoCompleteLocationRef.current.addListener(
      "place_changed",
      async function () {
        const Place = await autoCompleteLocationRef.current.getPlace();
        console.log(Place.geometry.location.lat(), "place");
        console.log(Place.geometry.location.lng(), "place");

        // setPlaceName(place.formatted_address);
        setLocation(Place?.formatted_address);
        console.log(Place?.name, "place");
      }
    );
  }, [Location]);

  useEffect(() => {
    if (data) {
      setEvents(data);
    } else {
      allEvents();
    }
  }, []);

  const Search = () => {
    try {
      setFilterLoader(true);
      const SearchData = new FormData();
      SearchData.append("event_day", day);
      // SearchData.append(
      //   "event_location",
      //   Location ? Location : LocationRef.current.value
      // );
      SearchData.append("event_state", State ? State : originref.current.value);
      SearchData.append("event_title", title);
      // SearchData.append("event_date", date);
      SearchData.append("region", Region);

      axiosInstance
        .post("user/filter/mat/data", SearchData)
        .then((response) => {
          if (response?.data?.status === true) {
            setFilterLoader(false);
            toast.success(response?.data?.message);
            console.log(response, "serach");
            setEvents(response?.data?.data);
          }
        });
    } catch (error) {
      setFilterLoader(false);
      toast.error("Error");
      console.error(error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 8;
  const allJobsCombined = [...events];
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = allJobsCombined?.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div class="section page-banner-section">
        <div class="shape-2"></div>
        <div class="container">
          <div class="page-banner-wrap">
            <div class="row">
              <div class="col-lg-12">
                {/* <!-- Page Banner Content Start --> */}
                <div class="page-banner text-center">
                  <h2 class="title">Open Mat</h2>
                </div>
                {/* <!-- Page Banner Content End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {events?.length > 0 ? (
        <>
          <div className="container mt-5">
            <div class="event-list-top-bar">
              <div class="row">
                <div class="col-lg-12">
                  <div class="event-list-search">
                    <form action="#">
                      <div class="row  mx-auto">
                        {/* <div className="col-lg-2 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-map"></i>
                            </label>
                            <Autocomplete>
                              <input type="text" ref={LocationRef} />
                            </Autocomplete>
                          </div>
                        </div> */}

                        {/* <div className="col-lg-2 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-search"></i>{" "}
                            </label>
                            <input
                              type="text"
                              placeholder="Type Event Name"
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-3 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-calendar"></i>
                            </label>
                            <select
                              className="filter"
                              onChange={(e) => setRegion(e.target.value)}
                            >
                              <option>Select Location</option>
                              {Regions?.map((e, index) => {
                                return (
                                  <>
                                    <option key={index} value={e}>
                                      {e}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-calendar"></i>
                            </label>
                            <select
                              className=" filter"
                              onChange={(e) => setDay(e.target.value)}
                            >
                              <option>Select Day</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Satuarday">Satuarday</option>
                              <option value="Sunday">Sunday</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-5 col-12">
                          <div class="single-form">
                            <label class="form-label">
                              <i class="fas fa-map"></i>
                            </label>
                            <Autocomplete>
                              <input
                                type="text"
                                ref={originref}
                                placeholder="Enter State"
                              />
                            </Autocomplete>
                          </div>
                        </div>

                        {/* <div className="col-lg-2 col-md-5 col-12">
                          <div class="single-form">
                            <input
                              type="date"
                              placeholder="Enter Date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                        </div> */}

                        <div class="col-md-3">
                          <div class="form-btn">
                            <button
                              class="btn btn-primary"
                              type="button"
                              onClick={Search}
                            >
                              {FilterLoader ? <Loader /> : "Find Event"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <!-- List Collapse Start --> */}
              <div class="collapse" id="collapseFilters">
                <div class="event-list-tag">
                  <ul>
                    <li>
                      <a href="#">Featured Event</a>
                    </li>
                    <li>
                      <a href="#">Upcoming</a>
                    </li>
                    <li>
                      <a href="#">Organizers</a>
                    </li>
                    <li>
                      <a href="#">Day</a>
                    </li>
                    <li>
                      <a href="#">Price</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- List Collapse End --> */}
            </div>
          </div>

          {loader ? (
            <div className="loader_overlay">
              <RotatingSquare
                visible={true}
                height="100"
                width="100"
                color="#fc097c"
                ariaLabel="rotating-square-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div className="container mb-5">
              <div className="row">
                <div className="col-md-7">
                  <div class="event-list-content-wrap">
                    <div class="row">
                    {currentJobs?.map((e, i) => {
                      return (
                        <>
                          <EventsCard data={e} index={i} />
                        </>
                      );
                    })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div class="card mt-3" style={{ height: "100vh" }}>
                    <div class="card-body">
                      <h1 class="card-title text-center">Ads Here</h1>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-5">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat: address[0]?.lat, lng: address[0]?.lng }}
                    zoom={5}
                  >
                    {address.map((e) => (
                      <MarkerF
                        position={{ lat: e?.lat, lng: e?.lng }}
                        options={
                          e?.lng.toString().includes("-")
                            ? markerOptions
                            : markerOptions2
                        }
                      />
                    ))}
                  </GoogleMap>
                </div> */}
              </div>
              <div className="row">
              <div className="mbp_pagination col-lg-6 text-center my-5">
                <ul className="page_navigation d-flex justify-content-start">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      <span className="fas fa-angle-left" />
                    </a>
                  </li>
                  {[
                    ...Array(
                      Math.ceil(allJobsCombined?.length / jobsPerPage)
                    ).keys(),
                  ]
                    .slice(currentPage - 1, currentPage + 2)
                    .map((number) => (
                      <li
                        key={number}
                        className={`page-item ${
                          currentPage === number + 1 ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => paginate(number + 1)}
                        >
                          {number + 1}
                        </a>
                      </li>
                    ))}
                  <li
                    className={`page-item ${
                      currentPage ===
                      Math.ceil(allJobsCombined?.length / jobsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      <span className="fas fa-angle-right" />
                    </a>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <h1 className="text-center mt-5" style={{ height: "30vh" }}>
          <Loader />
        </h1>
      )}
    </>
  );
};

export default EventList;
