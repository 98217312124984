/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CitiesSlider from "../Slider/CitiesSlider";

const Cities = () => {
  return (
    <>
    

      <div class="meeta-trending-section section-padding">
        <div class="trending-shape-1"></div>
        <img
          class="trending-shape-2"
          src="/assets/images/shape/trend-shape-2.png"
          alt=""
        />
        <img
          class="trending-shape-3"
          src="/assets/images/shape/trend-shape-3.png"
          alt=""
        />
        <img
          class="trending-shape-4"
          src="/assets/images/shape/trend-shape-4.png"
          alt=""
        />
        <img
          class="trending-shape-5"
          src="/assets/images/shape/trend-shape-5.png"
          alt=""
        />
        <div class="container">
          <div class="meeta-trending-wrap">
            {/* <!-- Section Title Start --> */}
            <div class="meeta-section-title section-title-4 text-center">
              <h2 class="main-title">
                Featured<span class="title-shape-2"> Open Mat</span>
              </h2>
            </div>
            {/* <!-- Section Title End --> */}
            <div class="meeta-trending-content-wrap meeta-trending-active slider-bullet">
              <CitiesSlider />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cities;
