/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import { toast } from 'react-toastify';
import Loader from "../../Components/loader/Loader";
import { useNavigate } from "react-router-dom"

const VerifyForm = () => {

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    const user_id = localStorage.getItem("user_id")

    const loginSchema = Yup.object().shape({
        email_otp: Yup.string().required("Required"),

    });

    const formik = useFormik({
        initialValues: {
            email_otp: "",
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            setLoader(true);
            console.log(values.errors, "formErrors");
            try {
                const loginData = new FormData();
                loginData.append("email_otp", values?.email_otp);
                loginData.append("user_id", user_id);
                axiosInstance.post("user/verification", loginData).then((response) => {
                    if (response?.data?.status === true) {
                        setLoader(false);
                        console.log(response, "OTP");
                        setSession(response?.data?.token, response?.data?.user);
                        toast.success('OTP Verified SuccessFully')
                        navigate("/login")

                    }
                });
            } catch (error) {
                setLoader(false);
                toast.error("Error")

                console.error(error);
            }
        },
    });

    return (
        <>
            <div class="section login-register-section section-padding">
                <div class="container">

                    {/* <!-- Login & Register Wrapper Start --> */}
                    <div class="login-register-wrap">
                        <div class="row gx-5 justify-content-center align-items-center">
                            <div class="col-lg-6">

                                {/* <!-- Login & Register Box Start --> */}
                                <div class="login-register-box">
                                    {/* <!-- Section Title Start --> */}
                                    <div class="section-title">
                                        <h2 class="title">OTP</h2>
                                    </div>
                                    {/* <!-- Section Title End --> */}

                                    <div class="login-register-form">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div class="single-form">
                                                <input type="text" class="form-control" placeholder="OTP" name='email_otp'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email_otp}
                                                />
                                            </div>
                                            {formik.errors.email_otp && (
                                                <div className="error">{formik.errors.email_otp}</div>
                                            )}
                                            <div class="form-btn">
                                                <button type='submit' class="btn-2">
                                                    {loader ? <Loader /> : "Verify"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <!-- Login & Register Box End --> */}

                            </div>

                        </div>
                    </div>
                    {/* <!-- Login & Register Wrapper End --> */}

                </div>
            </div>
        </>
    )
}

export default VerifyForm