/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/Dropdown";

const Header = () => {
  // const is_Authenticated = localStorage.getItem("accesstoken");
  // const user = JSON.parse(localStorage.getItem("user"));

  // const handleLogout = () => {
  //   localStorage.clear();
  //   window.location.href = "/";
  // };
  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="meeta-header-section meeta-header-2 meeta-header-3 meeta-header-5">
        {/* <!-- Header Middle Start --> */}
        <div class="header-middle header-sticky">
          <div class="container">
            <div class="header-wrap">
              {/* <!-- Header Logo Start --> */}
              <div class="header-logo header-logo-3">
                <Link class="logo-black" to="/">
                  <img
                    src="/assets/images/logo1.png"
                    alt="Logo"
                    width={170}
                    className="img-fluid"
                  />
                </Link>
                <Link class="logo-white" to="/">
                  <img
                    src="/assets/images/logo1.png"
                    alt="Logo"
                    width={170}
                    className="img-fluid"
                  />
                </Link>
              </div>
              {/* <!-- Header Logo End --> */}

              {/* <!-- Header Navigation Start --> */}
              <div class="header-navigation d-none d-lg-block">
                <ul class="main-menu">
                  <li class="active-menu">
                    <Link to="/" class="btns-3">Home</Link>
                    {/* <ul class="sub-menu">
                                            <li><a href="index.html">Home 01</a></li>
                                            <li><a href="index-2.html">Home 02</a></li>
                                            <li><a href="index-3.html">Home 03</a></li>
                                            <li><a href="index-4.html">Home 04</a></li>
                                            <li><a class="active" href="index-5.html">Home 05</a></li>
                                            <li><a href="index-6.html">Home 06</a></li>
                                        </ul> */}
                  </li>
                  {/* <li>
                    <Link to="#">About</Link>
                  </li> */}
                  <li>
                    <Link to="/events" class="btns-3">Open Mat</Link>
                  </li>
                  <li>
                    <Link to="/bestpractices" class="btns-3 px-5">Best Practices</Link>
                  </li>
                  {/* <li><a href="#">Pages</a>
                                        <ul class="sub-menu">
                                            <li><a href="speaker-one.html">Speakers 01</a></li>
                                            <li><a href="speaker-.html">Speakers 02</a></li>
                                            <li><a href="speaker-single.html">Speaker Single</a></li>
                                            <li><a href="event-list.html">Event List</a></li>
                                            <li><a href="event-single.html">Event Single</a></li>
                                            <li><a href="schedule.html">Event Schedule</a></li>
                                            <li><a href="gallery.html">Gallery</a></li>
                                            <li><a href="price.html">Pricing</a></li>
                                            <li><a href="faq.html">FAQ's</a></li>
                                            <li><a href="login-register.html">Login Register</a></li>
                                        </ul>
                                    </li> */}
                  {/* <li>
                    <a href="#">Blog</a>
                    <ul class="sub-menu">
                      <li>
                        <a href="blog.html">Blog Grid</a>
                      </li>
                      <li>
                        <a href="blog-standard.html">Latest News</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li>
                    <a href="contact.html">Contact</a>
                  </li> */}
                </ul>
              </div>
              {/* <!-- Header Navigation End --> */}

              {/* <!-- Header Meta Start --> */}
              <div class="header-meta">
                {/* <div class="header-btn d-none d-md-block">
                  {is_Authenticated ? (
                    <DropdownButton
                      id="dropdown-button-dark-example2"
                      variant="secondary"
                      title={user?.email}
                      className="mt-2"
                      data-bs-theme="dark"
                    >
                      <Dropdown.Item onClick={() => handleLogout()}>
                        Logout
                      </Dropdown.Item>
                    </DropdownButton>
                  ) : (
                    <Link to="/login" class="btn-2">
                      Login
                    </Link>
                  )}
                </div> */}

                {/* <!-- Header Toggle Start --> */}
                <div class="header-toggle d-lg-none">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
                {/* <!-- Header Toggle End --> */}
              </div>
              {/* <!-- Header Meta End --> */}
            </div>
          </div>
        </div>
        {/* <!-- Header Middle End --> */}
      </div>
      {/* <!-- Header End --> */}

      <div class="offcanvas offcanvas-start" id="offcanvasExample">
        <div class="offcanvas-header">
          {/* <!-- Offcanvas Logo Start --> */}
          <div class="offcanvas-logo">
            <a href="#">
              <img
                src="/assets/images/logo1.png"
                alt="Logo"
                width={100}
                className="img-fluid"
              />
            </a>
          </div>
          {/* <!-- Offcanvas Logo End --> */}
          <button type="button" class="close-btn" data-bs-dismiss="offcanvas">
            <i class="flaticon-close"></i>
          </button>
        </div>

        {/* <!-- Offcanvas Body Start --> */}
        <div class="offcanvas-body">
          <div class="offcanvas-menu offcanvas-menu-2">
            <ul class="main-menu">
              <li>
                <Link to="/" class="">Home</Link>
              </li>
              <li>
                <Link to="/events" class="">Open Mat</Link>
              </li>
              <li>
                <Link to="/bestpractices" class="">Best Practices</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Offcanvas Body End -->/ */}
      </div>
    </>
  );
};

export default Header;
